import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/seo';
import Inner from '../components/Inner';
import * as Styles from '../styles/pages/_privacy.module.scss';
import CompliancePageWrapper from '../components/compliance-page-wrapper';
// import ISO_IMAGE from '../images/security_iso.jpg';
import SecurityPrivacyTitle from '../components/SecurityPrivacyTitle';
import BackButton from '../components/back-button';

export default function About() {
  const breadcrumb = [{ label: '情報セキュリティ方針', url: '/security' }];

  return (
    <>
      <Seo
        url="/security"
        title="情報セキュリティ｜Uniforce（ユニフォース）株式会社"
        description="Uniforce株式会社での情報セキュリティに関し、漏洩・き損・滅失等のリスクから、情報資産を保護するためのポリシーを掲載しています。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb}>
        <CompliancePageWrapper>
          <Inner>
            <SecurityPrivacyTitle en="Security" jp="情報セキュリティ方針" />
            <div className={Styles.privacy}>
              <div className={Styles.privacy__text}>
                <h3 className={Styles.privacy__subtitle}>基本理念</h3>
                <p>
                  当社の事業の中で取り扱う、お客様の情報をはじめとする情報資産は、当社の経営基盤として極めて重要なものです。
                  <br />
                  漏洩、き損、滅失等のリスクから、情報資産を保護することの重要性を認識した役員や従業員を含めた、
                  <br />
                  情報資産を扱う者が本方針を遵守し、情報資産の機密性、完全性、可用性といった情報セキュリティを維持するための活動を実践します。
                </p>
                <h3 className={Styles.privacy__subtitle}>基本方針</h3>
                <ol>
                  <li>
                    情報資産を保護するために、情報セキュリティ方針ならびに、それにまつわる規程類を策定し、これに従って業務を行うとともに、情報セキュリティに関連する法令、規制その他の規範、及び、お客様との契約事項を遵守いたします。
                  </li>
                  <li>
                    情報資産に対して存在する漏洩、き損、滅失等のリスクを分析、評価するための基準を明確にし、体系的なリスクアセスメント方法を確立するとともに、定期的にリスクアセスメントを実施いたします。また、その結果に基づき、必要かつ適切なセキュリティ対策を実施いたします。
                  </li>
                  <li>
                    担当役員を中心とした情報セキュリティ体制を確立するとともに、情報セキュリティに関する権限および責任を明確にいたします。また、すべての従業者が、情報セキュリティの重要性を認識し、情報資産の適切な取り扱いを確実にするために、定期的に教育、訓練および啓発を行います。
                  </li>
                  <li>
                    情報セキュリティポリシーの遵守状況及び情報資産の取扱いについて、定期的に点検及び監査を行い、発見された不備や改善項目については、速やかに是正処置を講じます。
                  </li>
                  <li>
                    情報セキュリティ上のイベントやインシデントの発生に対する適切な処置を講じるとともに、万一それらが発生した場合に際して、あらかじめ、被害を最小限に留めるための対応手順を確立し、有事の際には、速やかに対応するとともに、適切な是正処置を講じます。また、特に、業務中断に関わるようなインシデントについては、その管理の枠組みを確立し、定期的に見直しを行うことにより、当社の事業継続を確実にいたします。
                  </li>
                  <li>
                    基本理念を実現するための目標を定めた情報セキュリティマネジメントシステムを確立し、これを実行するとともに、継続的に見直し、改善を行います。
                  </li>
                </ol>
              </div>
              <div className={Styles.privacy__name}>
                <div className={Styles.privacy__history}>
                  <h3>改定履歴</h3>
                  <p>
                    ２０２１年１２月１７日制定
                    <br />
                    ２０２２年１０月５日改訂
                  </p>
                </div>
                <div className={Styles.privacy__namebox}>
                  Uniforce株式会社
                  <br />
                  代表取締役　砂田 和也
                </div>
              </div>
            </div>

            {/* <div className={Styles.privacy__isoText}>
              <div>
                <h3 className={Styles.privacy__isoTitle}>
                  ISO 27001（ISMS）の取得
                </h3>
                <p>
                  Uniforce株式会社は、情報セキュリティマネジメントシステムに関する国際標準規格「ISO27001（ISMS）」の認証を2022年3月29日に取得。定期的に開催される取締役会およびISMSマネジメントレビューによって、内部統制の整備と監督を行っており、情報の機密性・完全性・可用性の維持、改善に日々取り組んでいます。
                </p>
              </div>
              <img
                src={ISO_IMAGE}
                alt=""
                className={Styles.privacy__isoImage}
              />
            </div> */}

            <div className={Styles.button__wrapper}>
              <div className={Styles.privacy__btn}>
                <BackButton path="/" />
              </div>
            </div>
          </Inner>
        </CompliancePageWrapper>
      </Layout>
    </>
  );
}
